declare global {
  interface Window {
    gtag: any;
    dataLayer: any[];
    google_optimize: any;
  }
}

/**
  * @param gtag.js
  * https://developers.google.com/analytics/devguides/collection/gtagjs/events
*/

const GA_Tracking = () => {
  const scriptId = "global-site-tag";
  // if Google Analytics is already loaded, do nothing
  if (document.getElementById(scriptId) === null) {
    const a = document.createElement('script');
    const m = document.getElementsByTagName('script')[0];
    a.id = scriptId
    a.async = true;
    a.src = "https://www.googletagmanager.com/gtag/js?id=" + process.env.REACT_APP_GA_TRACKING_ID;

    // bind dataLayer to window
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    function gtag() { window.dataLayer.push(arguments); }
    window.gtag = gtag;

    if (m.parentNode) {
      m.parentNode.insertBefore(a, m);
      window.gtag('js', new Date());
      window.gtag('config', process.env.REACT_APP_GA_TRACKING_ID, { 'send_page_view': false });
    }
  }
}

export default GA_Tracking;