import React, { Suspense } from 'react';
import { Routes, Route } from "react-router-dom";

import GA_Tracking from './utils/gtag';

// States
import AuthState from "./context/AuthState";

// Pages
import Home from './pages/Home';
import PartnersHome from './pages/PartnersHome';

// Templates
import DigitalCard from './templates/DigitalCard';
import PixelNexusConnectOtterbox2023 from './templates/PixelNexusConnectOtterbox2023';



const App = () => {
  if (process.env.NODE_ENV === 'production') {
    GA_Tracking();
  }

  return <AuthState>
    <Suspense fallback="">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:partner" element={<PartnersHome/>} />
        <Route path="/:partner/directory/:contact" element={<DigitalCard/>} />
        <Route path="/:partner/pixel-nexusconnect-otterbox" element={<PixelNexusConnectOtterbox2023 />} />
      </Routes>
    </Suspense>
  </AuthState>;
}

export default App