import React, { Fragment, useEffect } from 'react';
import cld from '../../constants/cld';
import { AdvancedImage, responsive } from '@cloudinary/react';
import { scale } from "@cloudinary/url-gen/actions/resize";
import "./index.scss";


const PixelNexusConnectOtterbox2023 = () => {

  // Cloudinary images
  const trinityPixelNexusConnectOtterbox = cld.image('landing-pages/pixel-otterbox-nexusconnect-2023/pixel-nexusconnect-otterbox-trinity');
  const pixelForBuisnessLogo = cld.image('partners/pixel-for-business');
  const ipcmobileLogo = cld.image('logos/ipcmobile-logo');
  const otterBoxLogo = cld.image('partners/OB_Main_Badge');

  const pixelErs = cld.image('landing-pages/pixel-otterbox-nexusconnect-2023/pixel-ers');
  const pixel247 = cld.image('landing-pages/pixel-otterbox-nexusconnect-2023/pixel-24-7');
  const waterDustDropProof = cld.image('landing-pages/pixel-otterbox-nexusconnect-2023/water-dust-drop-proof');
  const fiveGPixelSecurity = cld.image('landing-pages/pixel-otterbox-nexusconnect-2023/5g-pixel-security');

  const box = cld.image('landing-pages/pixel-otterbox-nexusconnect-2023/box');
  const feather = cld.image('landing-pages/pixel-otterbox-nexusconnect-2023/feather');
  const moneySign = cld.image('landing-pages/pixel-otterbox-nexusconnect-2023/money-sign');

  // Cloudinary image transformations
  pixelForBuisnessLogo.resize(scale().width(200));
  ipcmobileLogo.resize(scale().width(200));
  otterBoxLogo.resize(scale().width(60));

  pixelErs.resize(scale().width(600));
  pixel247.resize(scale().width(600));
  waterDustDropProof.resize(scale().width(600));
  fiveGPixelSecurity.resize(scale().width(600));

  useEffect(() => {

  }, []);

  document.body.className = "pixel-nexusconnect-otterbox-2023";
  
  return <Fragment>
    <main>
      <section className="billboard">
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <h1>Price. Performance. Productivity. </h1>
              <div className="billboard__brands d-flex flex-column flex-md-row justify-content-center">
                <div className="row">
                  <div className="col brand">Google&nbsp;Pixel</div>
                  <div className="col brand">IPCMobile&nbsp;NexusConnect</div>
                  <div className="col brand">OtterBox&nbsp;uniVERSE</div>
                </div>
              </div>
              <AdvancedImage
                cldImg={trinityPixelNexusConnectOtterbox}
                plugins={[responsive({ steps: [600, 1000] })]}
                className="img-fluid billboard__hero"
                alt="Trinity Pixel NexusConnect Otterbox"
              />
              <div className="billboard__logos d-flex flex-column flex-md-row justify-content-center">
                <div className="row">
                  <div className="col">
                    <AdvancedImage
                      cldImg={pixelForBuisnessLogo}
                      alt="Google Pixel for Buisness"
                      className="logo__google"
                    />
                  </div>
                  <div className="col">
                    <AdvancedImage
                      cldImg={ipcmobileLogo}
                      alt="Google Pixel for Buisness"
                      className="logo__ipcmobile"
                    />
                  </div>
                  <div className="col">
                    <AdvancedImage
                      cldImg={otterBoxLogo}
                      alt="Google Pixel for Buisness"
                      className="logo__otterbox"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="features">
        <div className="fluid-container mx-auto text-center">
          <div className="row">
            <div className="col-6">
              <AdvancedImage
                cldImg={pixelErs}
                alt="Pixel ERS"
                className="features__image"
              />
              <p>
                <strong>6&Prime; to 45-plus</strong> feet of scanning range.
                <span className="d-block"></span>
                <strong>Five years of security</strong> updates.<sup>1</sup>
                <span className="d-block"></span>
                <strong>71,000 scans</strong> on a single charge.
              </p>
            </div>
            <div className="col-6">
              <AdvancedImage
                cldImg={pixel247}
                alt="Pixel 24/7"
                className="features__image"
              />
              <p>
                <strong>A battery</strong> that provides <strong>all-day productivity.<sup>2</sup></strong>
                <span className="d-block"></span>
                <strong>A modular, rugged</strong> case.
                <strong>A powerful, revolutionary Tensor</strong> processor.
              </p>
            </div>
            <div className="col-6">
              <AdvancedImage
                cldImg={waterDustDropProof}
                alt="Water Dust Drop Proof"
                className="features__image"
              />
              <p>
                <strong>Dust, water,<sup>3</sup> and drop</strong> protected. Flexible software. Wireless <strong>Qi Charging.<sup>4</sup></strong>
              </p>
            </div>
            <div className="col-6">
              <AdvancedImage
                cldImg={fiveGPixelSecurity}
                alt="5G Pixel Security"
                className="features__image"
              />
              <p>
                <strong>Privacy and security</strong> your way, for business. <strong>High-Res</strong> display. <strong>5G</strong> connectivity.<sup>5</sup>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="value-props">
        <h2>Built for business longevity</h2>
        <div className="value-props__cards d-flex gap-2 mx-auto">
          <div className="cards cards__card-1 d-flex gap-2 p-3">
            <AdvancedImage
              cldImg={box}
              alt="open box"
              className="card__image"
            />
            <p className="text-center m-0">
              Out-of-the-box deployment.
              <span className="d-block"></span>
              Smooth integration.
              <span className="d-block"></span>
              The latest collaboration tools
            </p>
          </div>
          <div className="cards cards__card-2 d-flex gap-2 p-3">
            <AdvancedImage
              cldImg={feather}
              alt="feather"
              className="card__image"
            />
            <p className="text-center m-0">
              Lightweight design.
              <span className="d-block"></span>
              Bluetooth connectivity.
              <span className="d-block"></span>
              Easy to use
            </p>
          </div>
          <div className="cards cards__card-3 d-flex gap-2 p-3">
            <AdvancedImage
              cldImg={moneySign}
              alt="money sign"
              className="card__image"
            />
            <p className="text-center m-0">
              Cost-effective
              <span className="d-block"></span>
              Business-Ready
              <span className="d-block"></span>
              Committed to Mobility
            </p>
          </div>
        </div>
      </section>
      <section className="cta">
        <div className="cta__text">Individually, each is extraordinary. Together, they become</div>
        <div className="cta__headline">versatility: future-proofed</div>
        <div className="cta__text">in the form of a <strong>superior, long-range scanning solution.</strong></div>
        <a
          href="https://www.barcodesinc.com/solutions/ipc-mobile-google-pixel-otterbox-sled-bundle/"
          target="_blank"
          rel="noopener noreferrer"
          className="cta__link d-block btn btn-primary mx-auto"
        >
          <span>Get in touch</span>
        </a>
      </section>
    </main>
    <footer>
      <div className="fluid-container mx-auto">
        <div className="row">
          <div className="col">
            <div className="trademarks">Google and Pixel are trademarks of Google LLC.</div>
            <p>
              <ol>
                <li>Security updates for at least 5 years from when the device first became available on the Google Store in the US. Updates may also include feature drops and other software updates. See g.co/pixel/updates for more information.</li>
                <li>Estimated battery life based on testing using a median Pixel user battery usage profile across a mix of talk, data, standby, and use of other features. Average battery life during testing was approximately 31 hours. Battery testing conducted on a major carrier network.</li>
                <li>Designed to comply with dust and water protection rating IP68 under IEC standard 60529 when each device is not water or dust proof. The accessories are not water or dust resistant. Water resistance and dust resistance are not permanent.</li>
                <li>Wireless charging rates up to 20W (Pixel 7) and up to 23W (Pixel 7 Pro) charging with Google Pixel Stand (2nd gen) (sold separately). Up to 12W with Qi-certified EPP chargers (sold separately). Actual results may be slower.</li>
                <li>Requires a 5G data plan (sold separately). 5G service not available on all carrier networks or in all areas. Contact carrier for details. 5G service, speed and performance depend on many factors, including carrier network capabilities and signal strength. Actual results may vary. Some features not available in all areas. Data rates may apply. See g.co/pixel/networkinfo for info.</li>
              </ol>
            </p>
          </div>
        </div>
      </div>
    </footer>
  </Fragment>
  
}

export default PixelNexusConnectOtterbox2023