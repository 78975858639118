import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext, db } from '../../context/AuthState';
import { doc, getDoc } from "firebase/firestore";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

import './index.scss';

import { IAction } from '../../types';

interface IState {
  booking: string | null;
  content: string | null;
  cover: {
    src: string | null,
    alt: string | null
  },
  dbLoaded: boolean;
  email: string | null;
  fname: string | null;
  isPublic: boolean;
  lname: string | null;
  mname: string | null;
  phone: any[];
  social: [];
  title: string | null;
  partnerName: string | null;
  profileImg: string | null;
  website: string | null;
  v_card: string | null;
}

const initialState: IState =  {
  booking: null,
  content: null,
  cover: {
    src: null,
    alt: null
  },
  dbLoaded: false,
  email: null,  
  fname: null,
  isPublic: false,
  lname: null,
  mname: null,
  partnerName: null,
  phone: [],
  profileImg: null,
  social: [],
  title: null,
  website: null,
  v_card: null
}

// Dynamic assets are news and blog
const DigitalCard: React.FC<any> = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { user } = useContext(AuthContext);
  // const { errors } = React.useContext(PageContext);
  const cardBody = document.querySelector<HTMLDivElement>(".digital-card__body")!;
  const cardFooter = document.querySelector<HTMLDivElement>(".digital-card__footer")!;

  const { partner, contact } = useParams<any>();

  useEffect((): any => {
    if (!user) return

    const getPageData = async () => {

      if (!contact || !partner) return props.history.push("/");

      const profileRef = doc(db, "partners", partner.toString().toLowerCase(), "digital-cards", contact.toString().toLowerCase());
      const profileDoc = await getDoc(profileRef);
      
      if (!profileDoc.exists()) return props.history.push("/");

      let data = profileDoc.data();

      const partnerRef = doc(db, "partners", partner.toString().toLowerCase());
      const partnerDoc = await getDoc(partnerRef);

      if (!partnerDoc.exists()) return props.history.push("/");

      data = {...data,...partnerDoc.data() };

      dispatch({
        type: "SET_PAGE_STATE",
        payload: data
      });
      // window.gtag('event', 'page_view', {
      //   page_title: `Digital Card - ${data.fname ? data.fname + " " : ""}${data.mname ? data.mname + " " : ""}${data.lname ? data.lname : ""}`,
      //   page_location: window.location.pathname
      // });
      cardBody.style.paddingBottom = cardFooter.offsetHeight + "px";

      // Get profile image
      const storage = getStorage();

      //search if image
      const listRef = ref(storage, `${partner.toString().toLowerCase()}/digital-cards/`);

      // List all files within directory
      listAll(listRef)
        .then((res) => {

          res.items.forEach((itemRef: any) => {
            // All the items under listRef
            const filename = itemRef.name.toString().substring(0, itemRef.name.toString().lastIndexOf("."));

            if (contact === filename) {
              getDownloadURL(ref(storage, `${partner.toString().toLowerCase()}/digital-cards/${itemRef.name}`))
                .then((url: string) => {
                  dispatch({
                    type: "SET_PROFILE_IMG",
                    payload: url
                  })
                });
            }
          });
        }).catch((error) => {
          // Uh-oh, an error occurred!
        });

    }

    getPageData();

  }, [partner, contact, cardBody, cardFooter, user, props]);

  document.title = `
    ${state.fname ? state.fname + " " : ""}
    ${state.mname ? state.mname + " " : ""}
    ${state.lname ? state.lname : ""}
    ${state.partnerName ? " - " + state.partnerName : ""}`;
    
  document.body.className = 'layout';

  return <div className="digital-card position-relative">
    <div className="digital-card__header position-relativ mb-4">
      <div className="digital-card__header-content position-relative d-flex justify-content-between align-items-end h-100">
        <div className="digital-card__profileImg-container rounded-circle">
          {!state.profileImg && <svg><rect width="100%" height="100%" fill="red" className='digital-card__profileImg img-fluid' /></svg>}
          {state.profileImg && <img
            className="digital-card__profileImg img-fluid"
            src={state.profileImg}
            alt={`
                ${state.fname ? state.fname + " " : ""}
                ${state.mname ? state.mname + " " : ""}
                ${state.lname ? state.lname : ""} profile
              `}
          />}
        </div>
        {/* <div>
            {state.booking && <a
              href={state.booking}
              className="digital-card__v-card d-inline-block text-white bg-green px-3 py-2 rounded"
              target="_blank"
              rel="noreferrer noopener"
              onClick={() => {
                window.gtag('event', 'Book Meeting Click', {
                  event_category: 'Digital business card',
                  event_label: `${state.fname ? state.fname + " " : ""}${state.mname ? state.mname + " " : ""}${state.lname ? state.lname : ""}`,
                  value: 1
                });
              }}
            >
              <img
                src="https://res.cloudinary.com/ipcmobile/image/upload/v1544812790/icons/download-arrow-white.svg"
                alt="download"
              />
              Save to Contacts
            </a>}
          </div> */}
        {/* <a
          href="/products/briefs"
          className="digital-card__v-card d-inline-block text-white bg-black px-3 py-2 rounded"
          target="_blank"
          rel="noreferrer noopener"
        >
          View Product Data Sheets
        </a> */}
      </div>
      <div className="digital-card__banner position-absolute">
        <img
          className="img-fluid"
          src={`${state.cover.src ? state.cover.src : "https://res.cloudinary.com/ipcmobile/image/upload/v1605037837/partials/infinite-peripherals-accessories.jpg"}`}
          alt={`${state.cover.alt ? state.cover.alt : "a group of devices"}`}
        />
      </div>
    </div>
    <div className="digital-card__body">
      <h1 className="digital-card__fullname">
        {state.fname && state.fname + " "}
        {state.mname && state.mname + " "}
        {state.lname && state.lname}
      </h1>
      {state.title && <p className="digital-card__title">
        {state.title}
      </p>}
      <div className="digital-card__phone-numbers">
        {/* {state.phone.length > 0 && state.phone[0].number} */}
        {(state.dbLoaded && state.phone.length) && state.phone.map((phone: any, i:number) => {    
          return <div key={i}>
            <span className="d-inline-block mb-3" style={{ width: "80px" }}>
              {
                phone.fieldName ?
                  phone.fieldName + ": ":
                  "Phone: "
              }
            </span>
            <a href={`tel:${phone.number? phone.number.replaceAll(" ", "") : ""}`} >
              {
                phone.number ? phone.number : "" +
                phone.ext ? " ext: " + phone.ext : ""
              }
            </a>
          </div>
        })}
        {state.dbLoaded && <div>
          <span className="d-inline-block mb-4" style={{ width: "80px" }}>Website:</span>
          <a
            href={state.website ? state.website : ""}
            target="_blank"
            rel="noreferrer noopener"
          >{state.website ? state.website : ""}</a>
        </div>}
        {state.v_card && <a
          href={state.v_card}
          className="digital-card__v-card d-inline-block text-white bg-green mb-5 px-3 py-2 rounded"
          target="_blank"
          rel="noreferrer noopener"
          // onClick={() => {
          //   window.gtag('event', 'Contact Info Saved', {
          //     event_category: 'Digital business card',
          //     event_label: `${state.fname ? state.fname + " " : ""}${state.mname ? state.mname + " " : ""}${state.lname ? state.lname : ""}`,
          //     value: 1
          //   });
          // }}
        >
          <img
            src="https://res.cloudinary.com/ipcmobile/image/upload/v1544812790/icons/download-arrow-white.svg"
            alt="download"
          />
          Save to Contacts
        </a>}
      </div>
      {state.content && <div className="digital-card__content" dangerouslySetInnerHTML={{ __html: state.content }} />}
      {state.social.length > 0 && <div className="fluid-container">
        <div className="row">
          <div className="col">
            <h4>Follow us on Social:</h4>
          </div>
        </div>
        <div className="row">
          {state.social.map((social: any, i: number) => {

            // logic
            switch (social.platform) {
              case "facebook":
                break;
              case "twitter":
                break;
              case "linkedin":
                break;
              case "youtube":
                break;
              case "instagram":
                break;
              default:
                break;
            }


            return <div className="col">
              <a
                href="https://www.linkedin.com/company/infinite-peripherals"
                className="digital-card__social d-flex justify-content-center align-items-center mb-3 py-2 rounded text-center text-white bg-blue"
                target="_blank"
                rel="noreferrer noopener"
              // onClick={() => {
              //   window.gtag('event', 'LinkedIn Click', {
              //     event_category: 'Digital business card',
              //     event_label: `${state.fname ? state.fname + " " : ""}${state.mname ? state.mname + " " : ""}${state.lname ? state.lname : ""}`,
              //     value: 1
              //   });
              // }}
              >
                <img
                  src="https://res.cloudinary.com/ipcmobile/image/upload/v1544812646/icons/linkedin-white.svg"
                  alt="LinkedIn"
                />
                <span>LinkedIn</span>
              </a>
            </div>;
          })}
        </div>
       
      </div>}
    </div>
    <div className="digital-card__footer position-fixed d-flex justify-content-between w-100">
      {state.phone && <a
        href={`tel:${state.phone.length > 0 ? state.phone[0].number.replaceAll(" ", "") : ""}`}
        className="digital-card__footer-cta-btn text-white bg-green py-2 rounded"
        onClick={() => {
          // window.gtag('event', 'Call', {
          //   event_category: 'Digital business card',
          //   event_label: `${state.fname ? state.fname + " " : ""}${state.mname ? state.mname + " " : ""}${state.lname ? state.lname : ""}`,
          //   value: 1
          // });
        }}
      >
        <img
          className="digital-card__call-btn"
          src="https://res.cloudinary.com/ipcmobile/image/upload/v1559586634/icons/phone-white.svg"
          alt="call"
        />
        <span>CALL</span>
      </a>}
      {state.email && <a
        href={`mailto:${state.email ? state.email : "mobilesales@ipcmobile.com"}`}
        className="digital-card__footer-cta-btn text-white bg-blue py-2 rounded"
        onClick={() => {
          // window.gtag('event', 'Email', {
          //   event_category: 'Digital business card',
          //   event_label: `${state.fname ? state.fname + " " : ""}${state.mname ? state.mname + " " : ""}${state.lname ? state.lname : ""}`,
          //   value: 1
          // });
        }}
      >
        <img
          className="digital-card__email-btn me-1"
          src="https://res.cloudinary.com/ipcmobile/image/upload/v1556830764/icons/mail-white.svg"
          alt="email"
        />
        <span>Email</span>
      </a>}
    </div>
  </div>;

};

const reducer = (state: IState, action: IAction): IState => {
  const { type, payload } = action;
  switch (type) {
    case "SET_PAGE_STATE":
      return {
        ...state,
        ...payload,
        dbLoaded: true
      }
    case "SET_PROFILE_IMG":
      return {
        ...state,
        profileImg: payload
      }
    default:
      return state;
  }
}

export default DigitalCard;