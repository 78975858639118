import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './app/App';
import "./app/stylesheets/root.scss";
import "bootstrap/dist/js/bootstrap.js";

const container = document.getElementById('app');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<BrowserRouter><App /></BrowserRouter>);